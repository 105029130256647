<template>
  <base-section
    id="social"
    class="brown lighten-5 text-center"
    space="56"
  >
    <base-section-heading
      title="ПК Апиуз"
    >
      Программный комплекс для автоматизации процесса подбора исполнителей на выполнение работ или оказание услуг по заданным критериям на заказы, размещаемые организациями.
    </base-section-heading>

    <!--      <a-->
    <!--        class="d-inline-block mb-8"-->
    <!--        href="https://vuetifyjs.com"-->
    <!--        style="text-decoration: none;"-->
    <!--        target="_blank"-->
    <!--      >-->
    <!--        https://vuetifyjs.com-->
    <!--      </a>-->

    <div class="py-4" />

    <v-btn
      class="font-weight-bold"
      color="white"
      light
      min-width="168"
      x-large
      @click="$router.push('/apiuz')"
    >
      Узнать больше

      <v-icon right>
        mdi-open-in-new
      </v-icon>
    </v-btn>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionNews',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
